<template>
  <PaymentLoader v-if="id && loading" :id="id" :token="token" @data="onData" @error="onError"></PaymentLoader>
  <ErrorPage v-if="errorcode > 0" :errorcode="errorcode"></ErrorPage>
  <div class="callback main" v-if="!loading && !errorcode">

    <div class="image">
      <img alt="Thumbs up" src="../assets/thumbsUp.svg" class="thumbs" />
    </div>

    <div class="paymentInfo">
      <h2 v-if="provider === 'TINK'" class="pn-l bold">{{ $t('callback.methods.tink.header') }}</h2>
      <h2 v-if="provider === 'SWISH' || provider === 'VIPPS'" data-testid="invoicePayed" class="pn-l bold">{{ $t('callback.methods.direct.header') }}</h2>
    </div>

    <PaymentInfo :payment="payment" :provider="provider" :amount="formatAmount(amount)"  :paymentOption="paymentOption" :ocr="ocr"></PaymentInfo>

    <OptIn v-if="optin" :payment-id="payment.paymentId" :methods="payment.optInMethods" :payment-recipient="payment.recipient"
      :token="token">
    </OptIn>
    <Autogiro v-if="autogiro" :payment-id="payment.paymentId">
    </Autogiro>
    <BrandingInfo />

  </div>
</template>
<script>
import { formatDate } from "../services/dateService.js";
import OptIn from "../components/OptIn.vue";
import ErrorPage from "../components/ErrorPage.vue";
import PaymentLoader from '../components/PaymentLoader.vue';
import PaymentInfo from '../components/PaymentInfo.vue';
import BrandingInfo from '../components/BrandingInfo.vue';
import Autogiro from "../components/AutoGiro.vue";

export default {
  name: "CallBack",
  props: ["token"],
  components: {
    Autogiro,
    OptIn,
    ErrorPage,
    PaymentLoader,
    PaymentInfo,
    BrandingInfo
  },
  data() {
    return {
      payment: {},
      email: "",
      mobile: "",
      loading: true,
      errorcode: 0,
      pdf: "",
      ongoing: null,
      id: null,
      provider: "",
      executionDate: null,
      externalPayment: null,
      paymentOption: null,
      ocr: "",
      amount: "",
      autogiro: false,
      optin: false,
    };
  },
  mounted() {
    this.id = this.$route.params.id;
  },
  methods: {
    onData(e) {
      this.payment = e.data;
      if (!e.done) {
        return;
      }

      if (e.error) {
        this.$router.push({
          name: 'payment',
          params: { id: this.payment.paymentId },
          query: { error: e.error }
        });
        return;
      }

      if ("APPROVED" !== e.data.status) {
        /**
         * We should never visit this page if we don't
         * have the correct status
         */
        this.$router.push({
          name: 'payment',
          params: { id: this.payment.paymentId }
        });
        return;
      }

      const REGEX = /^[a-zA-Z][a-zA-Z\d+\-.]*:\/\//;
      if (this.payment.customParameters && REGEX.test(this.payment.customParameters.callbackUrl)) {
        window.location.href=this.payment.customParameters.callbackUrl;
        return;
      }

      this.loading = false;

      this.pdf = e.data.invoiceUrl;
      this.provider = e.data.externalPayment.provider;
      this.executionDate = e.data.executionDate;

      this.externalPayment = e.data.externalPayment;
      this.paymentOption = this.findPaymentOption(e.data); 
      this.ocr = this.paymentOption.referenceValue;
      this.amount = this.externalPayment.amount;
      this.autogiro = this.payment.optInMethods.includes("AUTOGIRO");

      if (this.payment.optInMethods.includes("EMAIL") || this.payment.optInMethods.includes("SMS")) {
        this.optin = true;
      }
    },
    onError(error) {
      this.errorcode = error.response.status;
      this.loading = false;
    },
    formatAmount(amount) {
      if (typeof amount == 'number') {
        return amount.toFixed(2).replace(/\.00/, '');
      }
      return amount;
    },
    dateFormat(value) {
      return formatDate(value, this.$t);
    },
    findPaymentOption(p) {
      const paymentOption = p.paymentOptions.find((paymentOption) => {
        return paymentOption.optionNumber === this.externalPayment.paymentOptionNumber;
      });        
      if (paymentOption) {
        return paymentOption;
      }
      return p.paymentOptions[0];
    },
    getRequestConfig() {
      if (!this.token) {
        return;
      }
      return {
        headers: { 'Authorization': 'Bearer ' + this.token }
      };
    },
    submitEmail() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    },
    submitTelephone() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    },
    formatAccountNr(account) {
      return (
        "<strong>&middot;&middot;&middot;&middot; &middot;&middot;&middot;</strong> " +
        account.slice(account.length - 3)
      );
    },
  },
};
</script>
<style scoped>
.callback {
  max-width: 30em;
  margin: 0 auto;
}


.image,
.paymentInfo {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.thumbs {
      width: 120px;
      margin-top: 2em;
      margin-bottom: 4.8rem;
    }

.paymentInfo {
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
}

.download {
  width: 1em;
  margin: 0 0.5em -0.15em 0;
}

.details {
  margin-top: 4.9rem;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
}

.mobile {
  margin: 0 0.5em 0 0.5em;
  width: 2.5em;
  height: 2.5em;
  vertical-align: top;
}

button {
  margin: auto;
}

.pdf {
  text-decoration: none;
  font-weight: 500;
}

/*Mobile Query*/
@media only screen and (max-width: 480px) {
  .thumbs {
    width: 80px;
    margin-top: 0;
    margin-bottom: 1.2rem;
  }
}
</style>